import React, { Component } from "react";
import ChatBubble from "./ChatBubble";

const imageRobot ="/default.jpg";
const imageYou = "/default.jpg";


class ChatApp extends Component{
  state = { messages: [] };

  constructor(object) {
    super(); 
    
    this.state = {
      messages: [
         {
          type: 0,
          image: imageRobot,
          text: "Hello!",
        },
        {
          type: 0,
          image: imageRobot,
          text: "I am just here for telling you that i can introduce " + object.name + " to you! That's it. I am yet not very smart. :(",
        },
        {
          type: 0,
          image: imageRobot,
          text: "Shall i introduce " + object.name + " to you?",
        },
        {
          type: 0,
          image: imageRobot,
          text: "Please respond with yes or no (y/n)!",
        },
      ],
    };
  }

  handleNewMessage = text =>{
    var input = text.toString() ;

    if(input.toLowerCase() === "stop" || input.toLowerCase() === "stopp" || input.toLowerCase() === "pause"){
      this.setState({
        messages: this.state.messages.concat([
          {
          text,
          type: 1,
          image: imageYou,
          }
          ,{
          text: "Audio will be paused now:",
          type: 0,
          image: imageYou,
        }])
      });
      let doc = document.getElementById("audio_obj");
      doc.pause();
      return;
    }

    //text to speach
    if(input.toLowerCase() === "yes" || input.toLowerCase() === "y" || input.toLowerCase() === "ja" || input.toLowerCase() === "j" || input.toLowerCase().includes("play")){  
      this.setState({
        messages: this.state.messages.concat([
          {
            text,
          type: 1,
          image: imageYou,
          }
          ,{
          text: "Audio will be played now:",
          type: 0,
          image: imageYou,
        }])
      });
      //play audio
      let doc = document.getElementById("audio_obj");
      doc.play();       
    }
    else if(input.toLowerCase() === "no" || input.toLowerCase() === "n" || input.toLowerCase() === "nein"){
      this.setState({
        messages: this.state.messages.concat([{
          text,
          type: 1,
          image: imageYou,
        },{
          text: "Okay, if you want to hear it, just write 'play' into this chat.",
          type: 0,
          image: imageRobot,
        },
      ])
      });
    }
    else{
      this.setState({
        messages: this.state.messages.concat([{
          text,
          type: 1,
          image: imageYou,
        },
        {
          text: "I don't understand this message. If you want to hear the introduction, just write 'play' into this chat.",
          type: 0,
          image: imageRobot,
        }
      ])
      });
    }
  };

  render() {
    return (
      <ChatBubble
        messages={this.state.messages}
        onNewMessage={this.handleNewMessage}
      />
    );
  }
}

export default ChatApp;