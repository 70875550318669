export type AvailableLocales = "de" | "en";

export const defaultLocale: AvailableLocales = "de";
export const cookieName = "dbc_locale";

export enum Domain {
  General = "general",
  Links = "links",
  Footer = "footer",
}
