import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const GOOGLEIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="32.787"
    height="26.386"
    viewBox="0 0 32.787 26.386"
    {...props}
  >
    <path
      fill="#ea4335"
      d="m20.805 7.2659h1l2.85-2.85 0.14-1.21a12.81 12.81 0 0 0-20.84 6.25 1.55 1.55 0 0 1 1-0.06l5.7-0.94s0.29-0.48 0.44-0.45a7.11 7.11 0 0 1 9.73-0.74z"
    />
    <path
      fill="#4285f4"
      d="m28.715 9.4559a12.84 12.84 0 0 0-3.87-6.24l-4 4a7.11 7.11 0 0 1 2.61 5.64v0.71a3.56 3.56 0 1 1 0 7.12h-7.12l-0.71 0.72v4.27l0.71 0.71h7.12a9.26 9.26 0 0 0 5.26-16.93z"
    />
    <path
      fill="#34a853"
      d="m9.2047 26.346h7.12v-5.7h-7.12a3.54 3.54 0 0 1-1.47-0.32l-1 0.31-2.87 2.85-0.25 1a9.21 9.21 0 0 0 5.59 1.86z"
    />
    <path
      fill="#fbbc05"
      d="m9.2047 7.8559a9.26 9.26 0 0 0-5.59 16.6l4.13-4.13a3.56 3.56 0 1 1 4.71-4.71l4.13-4.13a9.25 9.25 0 0 0-7.38-3.63z"
    />
  </SvgIcon>
);
