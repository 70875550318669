import { Grid, Stack } from "@mui/material";

import { Container } from "@mui/system";
import React from "react";
import Header from "../Header/Header";
import ReactLoading from "react-loading";
import Footer from "../Footer/Footer";

export const LoadingView = () => {
  return (
    <div>
      <Header />
      <Container>
        <div className="loading">
          <Stack spacing={2}>
            <Grid alignItems="center">
              <ReactLoading
                type="bars"
                color="#e60000"
                height={50}
                width={100}
              />
            </Grid>
          </Stack>
        </div>
      </Container>
      <Footer />
    </div>
  );
};

export default LoadingView;
