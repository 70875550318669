import React from "react";
import { useTranslation } from "react-i18next";
import { ProfileContext, ProfileAddonContext } from "../../App";
import { UserProfile } from "../../models/UserProfile";
import {
  Box,
  Container,
  Row,
  Column,
  FooterLink,
  Heading,
} from "./FooterStyles";
import { Domain } from "../../common/i18n/";
import { Typography } from "@mui/material";

const Footer = () => {
  const { t } = useTranslation(Domain.General);

  const profileData = React.useContext(ProfileContext);
  const profileAddonData = React.useContext(ProfileAddonContext);

  const profile = new UserProfile(
    profileData?.getUserProfile!,
    profileAddonData?.getUserProfileAddons!,
    false
  );
  var name = "fme";
  if (profile.Id !== "Undefined") {
    name = profile.FirstName; //.toLowerCase();
  }

  return (
    <div className="footer" style={{ top: "5vh", position: "relative" }}>
      <Box>
        <Typography
          variant="h2"
          style={{ color: "#ffffff", textAlign: "center", marginTop: "-50px" }}
        >
          It’s {name} you love to work with.
        </Typography>

        <Container>
          <Row>
            <Column>
              <Heading>{t("footer.headers.about_us")}</Heading>
              <FooterLink href={t("links.company")}>
                {t("footer.labels.company")}
              </FooterLink>
              <FooterLink href={t("links.blog")}>
                {t("footer.labels.blog")}
              </FooterLink>
              <FooterLink href={t("links.contact")}>
                {t("footer.labels.contact")}
              </FooterLink>
              <FooterLink href={t("links.career")}>
                {t("footer.labels.career")}
              </FooterLink>
              <FooterLink href={t("links.imprint")}>
                {t("footer.labels.imprint")}
              </FooterLink>
            </Column>
            <Column>
              <Heading>{t("footer.headers.services")}</Heading>
              <FooterLink href={t("links.digital_transformation")}>
                {t("footer.labels.digital_transformation")}
              </FooterLink>
              <FooterLink href={t("links.business_consulting")}>
                {t("footer.labels.business_consulting")}
              </FooterLink>
              <FooterLink href={t("links.technology_services")}>
                {t("footer.labels.technology_services")}
              </FooterLink>
              <FooterLink href={t("links.cloud_services")}>
                {t("footer.labels.cloud_services")}
              </FooterLink>
              <FooterLink href={t("links.ecm_services")}>
                {t("footer.labels.ecm_services")}
              </FooterLink>
            </Column>
            <Column>
              <Heading>{t("footer.headers.products")}</Heading>
              <FooterLink href={t("links.dqman")}>dqMan</FooterLink>
              <FooterLink href={t("links.migration_center")}>
                Migration Center
              </FooterLink>
              <FooterLink href={t("links.vistaya")}>Vistaya</FooterLink>
            </Column>
            <Column>
              <Heading>{t("footer.headers.social_media")}</Heading>
              <FooterLink href={t("links_social.linkedin")}>
                <i className="fab fa-linkedin">
                  <span style={{ marginLeft: "10px" }}>LinkedIn</span>
                </i>
              </FooterLink>
              <FooterLink href={t("links_social.xing")}>
                <i className="fab fa-xing">
                  <span style={{ marginLeft: "10px" }}>Xing</span>
                </i>
              </FooterLink>
              <FooterLink href={t("links_social.facebook")}>
                <i className="fab fa-facebook-f">
                  <span style={{ marginLeft: "10px" }}>Facebook</span>
                </i>
              </FooterLink>
              <FooterLink href={t("links_social.instagram")}>
                <i className="fab fa-instagram">
                  <span style={{ marginLeft: "10px" }}>Instagram</span>
                </i>
              </FooterLink>
              <FooterLink href={t("links_social.twitter")}>
                <i className="fab fa-twitter">
                  <span style={{ marginLeft: "10px" }}>Twitter</span>
                </i>
              </FooterLink>
              <FooterLink href={t("links_social.youtube")}>
                <i className="fab fa-youtube">
                  <span style={{ marginLeft: "10px" }}>Youtube</span>
                </i>
              </FooterLink>
            </Column>
          </Row>
        </Container>
      </Box>
    </div>
  );
};
export default Footer;
