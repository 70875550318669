import React from "react";
import QRCode from "react-qr-code";
import VCard from "vcard-creator";
import { isMobile } from "react-device-detect";
import { Grid, IconButton, Paper, Stack, Typography } from "@mui/material";

import ReplyRoundedIcon from "@mui/icons-material/ReplyRounded";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import CallIcon from "@mui/icons-material/Call";
import MailOutlineRoundedIcon from "@mui/icons-material/MailOutlineRounded";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import AddLocationAltRoundedIcon from "@mui/icons-material/AddLocationAltRounded";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import LanguageIcon from "@mui/icons-material/Language";

import * as IconHelper from "../../helper/IconHelper";
import "./CardView.css";
import { ProfileAddonContext, ProfileContext } from "../../App";
import { UserProfile } from "../../models/UserProfile";

import { Navigate } from "react-router-dom";
import { GetPresignedImageUrl } from "../../models/Lambdas/PreSignedImage";
import ReactLoading from "react-loading";

export interface CardViewProps {
  isDigitalBusinessCard: boolean;
  isDBCOnly: boolean;
}

export const CardView = ({
  isDigitalBusinessCard,
  isDBCOnly,
}: CardViewProps) => {
  const companyUrl = "";
  const profileData = React.useContext(ProfileContext);
  const profileAddonData = React.useContext(ProfileAddonContext);
  const minWidth = 600; // for horizontal/vertical..
  const topSkillsActive = false; //deactivate for now..

  const [cardClicks, setCardClicks] = React.useState(0);

  const profile = new UserProfile(
    profileData?.getUserProfile!,
    profileAddonData?.getUserProfileAddons!,
    isDBCOnly
  );

  console.log("link is " + profile.ProfileUrl);

  const vcard = profile.createVCARD();

  function exportVCARD(vCard: VCard) {
    const blob = new Blob([vCard.toString()], { type: "text/x-vcard" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.download = profile.FullName + ".vcf";
    link.href = url;
    link.click();
  }

  function openMap() {
    let url = "https://goo.gl/maps/QAiRVyS316h1PqR1A";
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    link.click();
  }

  function showMaps(): JSX.Element {
    return (
      <IconButton
        style={{ padding: "0" }}
        onClick={openMap}
        color="primary"
        sx={{ "&:hover": { color: "red" } }}
      >
        <AddLocationAltRoundedIcon />
      </IconButton>
    );
  }

  function countrys(): JSX.Element {
    return <>Germany ⋅ Romania ⋅ USA ⋅ India</>;
  }

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  /*
  This function checks if the view is horizontal on mobile device (or if at least has the specified minWidth of screen..)
  */
  function isHorizontalView(): boolean {
    return getWindowDimensions().width >= minWidth;
  }

  function buttonView(): JSX.Element {
    return (
      <>
        <Typography>
          <IconButton
            onClick={() => {
              exportVCARD(vcard);
            }}
            color="primary"
            aria-label="download vCard"
            sx={{
              "&:hover": {
                color: "red",
              },
            }}
          >
            <SystemUpdateAltIcon
              style={{
                fontSize: isDigitalBusinessCard ? "xxx-large" : "xx-large",
              }}
            />
          </IconButton>
        </Typography>
        <Typography>
          <IconButton
            onClick={handleShareButton}
            color="primary"
            sx={{ "&:hover": { color: "red" } }}
          >
            <ReplyRoundedIcon
              style={{
                fontSize: isDigitalBusinessCard ? "xxx-large" : "xx-large",
              }}
            />
          </IconButton>
        </Typography>
        {profile.hasBookingLink() ? (
          <Typography>
            <IconButton
              onClick={handleBookingButton}
              color="primary"
              sx={{ "&:hover": { color: "red" } }}
            >
              <CalendarMonthRoundedIcon
                style={{
                  fontSize: isDigitalBusinessCard ? "xxx-large" : "xx-large",
                }}
              />
            </IconButton>
          </Typography>
        ) : (
          ""
        )}
      </>
    );
  }

  function getImage(): JSX.Element {
    var link = GetPresignedImageUrl();

    //if default -> Show loading-animation
    if (link === "default.jpg") {
      return (
        <Grid width="100%" height="100%">
          <Grid height="50%" width="50%" id="test">
            <ReactLoading
              width="100%"
              height="100%"
              type="spinningBubbles"
              color="#e60000"
            />
          </Grid>
        </Grid>
      );
    }

    return (
      <Grid>
        <img
          style={{
            width: "100%",
            height: "100%",
            borderRadius: "100% 0 100% 100%",
            textAlign: "center",
            boxShadow: isMobile ? "-2px 4px 10px -5px" : "-2px 7px 20px -5px",
          }}
          alt="not found"
          id="hiddenOnSmallScreens"
          width="100%"
          height="100%"
          onError={(e) => {
            e.currentTarget.src = "default.jpg";
          }}
          src={link}
        ></img>
      </Grid>
    );
  }

  const handleBookingButton = () => {
    const link = document.createElement("a");
    link.href = profile.BookingLink;
    link.target = "_blank";
    link.click();
  };

  const handleShareButton = () => {
    // Check if navigator.share is supported by the browser
    if (!navigator.share) {
      console.log("Sorry! Your browser does not support Web Share API");
      return;
    }

    console.log("Congrats! Your browser supports Web Share API");
    navigator
      //tbd: i18n -> labels ;-)
      .share({
        url: profile.ProfileUrl,
        /* files: [storeVCARD(vcard)], */
        title:
          profile.Company +
          ": Digital-Business-Card of " +
          profile.FirstName +
          " " +
          profile.LastName,
        text:
          "Hello,\n\nthis mail contains the digital business card of " +
          profile.FirstName +
          " " +
          profile.LastName +
          ".\n\n" +
          "To open the digital business card, you just need to click on the following link.\n\n" +
          "The Digital Business Card is powered by fme group ( " +
          profile.Homepage +
          " ).",
      })
      .then(() => {
        console.log("Sharing successfull");
      })
      .catch((e) => {
        console.log("Sharing failed" + e);
      });
  };

  //if you click 3 times -> open dba-card
  if (cardClicks > 2) {
    if (isDigitalBusinessCard) {
      return <Navigate to="/" />;
    }
    return <Navigate to="/digitalbusinesscard" />;
  }

  return (
    <div>
      <Paper
        onClick={() => {
          setCardClicks(cardClicks + 1);
        }}
        style={{
          margin: isMobile
            ? "2.5vw"
            : isDigitalBusinessCard
            ? "1vw"
            : "7vh 20vw -5vh 20vw",
          padding: "0.5rem",
          position: "relative",
          top: isMobile || isDigitalBusinessCard ? "" : "-5vh",
          zIndex: "9999",
        }}
        elevation={12}
        square
      >
        <Grid container>
          <Grid id="container_left" xs={1} />
          <Grid
            container
            id="container_central_left"
            padding="1rem"
            xs={isHorizontalView() ? 6 : 8}
          >
            <Stack
              alignItems="flex-start"
              direction="column"
              id="content_container"
              justifyContent="space-between"
            >
              <Stack id="header-row" direction="column">
                <Typography color="primary" variant="h2">
                  <b>{profile.FullName || "..."}</b>
                </Typography>
                <Typography color="primary" marginBottom="1.5vw" variant="h4">
                  <b>{profile.Role || "..."}</b>
                </Typography>
              </Stack>
              <Stack direction="column" id="contact-row">
                {profile.hasPhone() ? (
                  <Stack direction="row" spacing={2}>
                    <Typography color="primary" variant="h5" gutterBottom>
                      <CallIcon fontSize="small" />
                    </Typography>
                    <Typography variant="h5" gutterBottom>
                      <a
                        style={{
                          color: "#000000",
                          letterSpacing: "1px",
                          textDecoration: "none",
                        }}
                        href={profile.PhoneToString()}
                      >
                        <b>{profile.transformPhone()}</b>
                      </a>
                    </Typography>
                  </Stack>
                ) : (
                  ""
                )}
                {profile.hasMobilePhone() ? (
                  <Stack direction="row" spacing={2}>
                    <Typography color="primary" variant="h5" gutterBottom>
                      <PhoneAndroidIcon fontSize="small" />
                    </Typography>
                    <Typography variant="h5" gutterBottom>
                      <a
                        style={{
                          color: "#000000",
                          letterSpacing: "1px",
                          textDecoration: "none",
                        }}
                        href={profile.MobilePhoneToString()}
                      >
                        <b>{profile.transformMobilePhone()}</b>
                      </a>
                    </Typography>
                  </Stack>
                ) : (
                  ""
                )}
                <Stack direction="row" spacing={2}>
                  <Typography color="primary" variant="h5" gutterBottom>
                    <MailOutlineRoundedIcon fontSize="small" />
                  </Typography>
                  <Typography variant="h5" gutterBottom>
                    <Grid
                      style={{
                        wordWrap: "break-word",
                        whiteSpace: "break-spaces",
                      }}
                    >
                      <a
                        style={{
                          color: "#000000",
                          letterSpacing: "1px",
                          textDecoration: "none",
                        }}
                        href={profile.MailToString()}
                      >
                        <b>{profile.Mail}</b>
                      </a>
                    </Grid>
                  </Typography>
                </Stack>
              </Stack>
              <Stack direction="column" id="address-row">
                <Stack direction="row" spacing={2}>
                  <Typography color="primary" variant="h5" gutterBottom>
                    {showMaps()}
                  </Typography>
                  <Stack direction="column">
                    <Typography variant="h5" gutterBottom>
                      {/* To do: add information from backend */}
                      {profile.Company}
                    </Typography>
                    <Typography variant="h5" gutterBottom>
                      {/* To do: add information from backend */}
                      {profile.Street}
                    </Typography>
                    <Typography variant="h5" gutterBottom>
                      {/* To do: add information from backend */}
                      {profile.PlzCity} | Germany
                    </Typography>
                    <Typography variant="h5" gutterBottom>
                      {/* To do: add information from backend */}
                      <a
                        style={{
                          color: "black",
                          letterSpacing: "1px",
                        }}
                        href={profile.Homepage}
                      >
                        <b>{profile.Homepage}</b>
                      </a>
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
              <Stack direction="column" id="footer-row">
                <Stack
                  alignItems="center"
                  direction="row"
                  gap={1}
                  style={{ wordBreak: "break-word", marginTop: "10px" }}
                >
                  <Typography color="primary" gutterBottom fontWeight="big">
                    <LanguageIcon fontSize="small" />
                  </Typography>
                  <Typography color="black" gutterBottom fontWeight="big">
                    <b>fme group</b> |
                  </Typography>
                  <Typography color="black" variant="h5" gutterBottom>
                    {countrys()}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Grid>
          {isHorizontalView() && (
            <Grid container id="container_center_right" padding="1rem" xs={2}>
              <Stack direction="column" justifyContent="space-around">
                {topSkillsActive ? (
                  <Stack
                    direction="column"
                    style={{ wordWrap: "break-word", marginLeft: "-5vw" }}
                  >
                    <Typography color="primary" variant="h5" gutterBottom>
                      {IconHelper.getIconByName(profile.skillByIndex(0))}
                    </Typography>
                    <Typography color="primary" variant="h5" gutterBottom>
                      {IconHelper.getIconByName(profile.skillByIndex(1))}
                    </Typography>
                    <Typography color="primary" variant="h5" gutterBottom>
                      {IconHelper.getIconByName(profile.skillByIndex(2))}
                    </Typography>
                  </Stack>
                ) : (
                  ""
                )}
                <Stack direction="column">{buttonView()}</Stack>
              </Stack>
            </Grid>
          )}
          <Grid
            item
            xs={3}
            id="container_right"
            width="100%"
            height="100%"
            position={"relative"}
          >
            <Grid
              height="100%"
              width={
                isMobile ? (isDigitalBusinessCard ? "90%" : "100%") : "100%"
              }
            >
              <Grid gridRow={0} width="100%" height="100%" paddingBottom="10px">
                {getImage()}
              </Grid>
              <Grid
                gridRow={1}
                width="100%"
                height="100%"
                paddingLeft={
                  isMobile ? (isHorizontalView() ? "30%" : "0") : "30%"
                }
                paddingBottom={isMobile ? "0" : "0.5rem"}
              >
                <QRCode
                  size={256}
                  style={{
                    height: "auto",
                    maxWidth: "100%",
                    width: "100%",
                    borderRadius: "0 0 0 0",
                    marginBottom: "-1vh",
                    paddingLeft: isMobile
                      ? isHorizontalView()
                        ? "30%"
                        : "0"
                      : "30%",
                  }}
                  value={profile.ProfileUrl}
                  viewBox={`0 0 256 256`}
                />
              </Grid>
            </Grid>
            {!isHorizontalView() && (
              <>
                <Grid
                  container
                  id="container_center_right"
                  padding="1rem"
                  xs={2}
                >
                  <Stack
                    direction="column"
                    alignContent="center"
                    justifyContent="flex-end"
                  >
                    {buttonView()}
                  </Stack>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default CardView;
