/* 
This function transforms the string to a valid one.
 */
export function transformString(aiString: string): string {
  var aoString = aiString;

  aoString = aoString.replaceAll("\\n", "<br>");

  return aoString;
}
