import React from "react";
import { AppBar, Typography } from "@mui/material";
import "./Header.css";

export function Header() {
  return (
    <AppBar position="sticky">
      <div className="header">
        <Typography id="slogan" sx={{ marginTop: "1.5rem", marginLeft: "1.5rem" }} variant="h4">It’s fme you love to work with.</Typography>
        <a href="https://fme.de">
          <img src="/fme.svg" alt="fme group" />
        </a>
      </div>
    </AppBar>
  );
}

export default Header;
