import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const AZUREIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <g transform="translate(677.93 -313.85)">
      <g transform="matrix(.039782 0 0 .039782 -658.51 317.36)">
        <path
          d="m-259.19 274.58c63.564-11.229 116.06-20.52 116.67-20.648l1.0963-0.2325-60.01-71.38c-33.006-39.259-60.01-71.532-60.01-71.719 0-0.35223 61.966-170.99 62.314-171.6 0.11647-0.20297 42.286 72.601 102.22 176.48 56.113 97.256 102.38 177.44 102.81 178.19l0.78184 1.3662-381.43-0.0491 115.57-20.416zm-228.88-21.768c0-0.1005 28.277-49.186 62.837-109.08l62.837-108.9 73.229-61.454c40.276-33.8 73.337-61.495 73.47-61.546 0.13255-0.0505-0.39727 1.283-1.1774 2.9633s-36.562 78.43-79.516 170.55l-78.098 167.5-56.79 0.0712c-31.235 0.0392-56.79-0.011-56.79-0.11142z"
          fill="#0089d6"
          strokeWidth=".28222"
        />
      </g>
    </g>
  </SvgIcon>
);
