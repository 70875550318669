import { API } from "aws-amplify";
import React, { useEffect } from "react";
import { ImageContext } from "../../App";
import * as queries from "../../graphql/queries";

export function GetPresignedImageUrlFunction(aiProfileId: String): string{
    const [urlData, setUrlData] = React.useState<any>(null);
  
    var url = "default.jpg";
  
    useEffect(() => {
      const getUrlData = async () => {
        const data = await API.graphql({
          query: queries.generatePresignedImageUrl,
          variables: { profileId: aiProfileId}
        });
        const { data: innerData } = (data as any)!;
        setUrlData(innerData);
      };

      getUrlData();
    }, []);

    if (urlData !== null) {
      if (urlData.generatePresignedImageUrl!.body.url !== null){
        url = urlData.generatePresignedImageUrl!.body.url;
      }
      else{
        console.log("error from s3-lambda:", urlData.generatePresignedImageUrl!.body.message);
      }
    }
    return url;
  }

export function GetPresignedImageUrl(): string{
    var url = "default.jpg";

    const imageData = React.useContext(ImageContext);

    if (imageData !== null) {
      if (imageData.generatePresignedImageUrl!.body!.url !== null){
        url = imageData.generatePresignedImageUrl!.body!.url!;
      }
      else{
        console.log("error from s3-lambda:", imageData.generatePresignedImageUrl!.body!.message);
      }
    }
    return url;
  }