import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const JAVAIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="531.02"
    height="739.8"
    enableBackground="new 0 0 1000 1000"
    viewBox="0 0 531.02 739.8"
    {...props}
  >
    <g transform="translate(-234.36 -10.1)">
      <path d="m668.9 407.9-1.1 0.8c-0.2 0.2-5.5 4.1-8.3 6.3l-12.6 10.1 16 2.5c1 0.2 2.5 0.3 4.7 0.5 27.4 2.4 43.7 7.2 48.2 14.3 7.9 12.3 7.4 26.1-1.5 37.7-10.1 13.2-23 24.6-37.7 37-6.2 5.3-13.6 9.7-21.4 14.5-3.8 2.3-7.8 4.7-11.8 7.4l-22.7 14.7 27-1.7c35.5-2.2 84.5-28.6 105-56.5 12.6-17.2 16.1-38.2 9.2-56.3-6.5-17.3-21.4-29.6-40.8-33.9l-0.7-0.1c-6.1-0.7-12.3-1.1-18.6-1.1-18.5 0-31.2 3.3-31.7 3.4z" />
      <path
        d="m472.7 398.9c6.3 5.9 12.8 12.1 18.9 18.3l4.4 4.5 5.8-4.5c0.9-0.7 1.8-1.4 2.8-2.2l5.2-4-3.6-5.5c-3.9-5.8-7.7-11.6-11.6-17.4-8.5-12.8-17.3-26-25.8-39.1-23.9-37-21.5-67.6 7.9-99.3 9.9-10.7 21.5-20.5 32.7-30 4-3.4 8.1-6.9 12.1-10.4 31.6-27.6 62.2-59.4 72.7-104.5 7-30.2 1-55.6-17.9-75.7l-17.9-19 5.7 25.4c9 39.7-2.1 74.3-33.8 105.6-6.4 6.3-13.4 12.3-20.3 18.1-5 4.2-10.1 8.5-15 13-6.8 6.3-13.9 12.6-20.7 18.7-20 18-40.7 36.6-59.7 56.3-15.8 16.3-18.8 38.8-8.9 64.9 14 36.6 41 62.1 67 86.8z"
        fill="#f00"
      />
      <path d="m390.6 484.3h0.5c0.8 0 1.5 0 2.3 0.1l-7 3.5c-9 4.5-28 20.2-26.2 37.5 1.3 12.5 12.6 21.5 33.5 26.9 18.7 4.8 41.9 7.2 68.9 7.2 76.4 0 161-19.4 161.8-19.6l5.5-1.3v-5.6c0-2.5 0-4.6-18.1-27.9l-2.8-3.6-4.5 1.1c-0.7 0.2-69.1 16.4-164.2 16.4-7 0-14-0.1-20.9-0.3-12.6-0.3-21.1-3.2-24.1-8-3.2-5.4 0.5-13.2 0.5-13.2l6.7-13h3.8c115.6 0 240-35.1 241.3-35.4l-2.5-13.9c-1 0.1-53.5 4.2-107.4 8.2 4.5-4.6 9-8.9 13.4-13 10-9.5 19.4-18.5 26.9-29 14.9-21.1 14.5-44.2-1-65.2-5.7-7.7-11.3-16.1-18.4-27.3-13.6-21.6-13-34.4 2.5-52.5 9.7-11.4 21.7-22.4 36.9-33.8 12.7-9.6 26.3-18.4 40.7-27.8 6.5-4.3 13.3-8.7 20.3-13.3l24.5-16.4-29.3 3.4c-2 0.2-3.6 0.3-5 0.4-2.7 0.2-5.1 0.3-7.5 1.4-8.3 3.7-16.8 7.4-25.1 11-21.6 9.4-44 19-65 30.8-15.7 8.9-31 23.2-42.9 40.3-23.6 33.8-19.6 70.5 12.3 112.1 21.8 28.5 22.1 33.1 4 62.7-1.6 2.6-1.2 4.9-0.9 7.3 0.2 1.2 0.4 2.8 0.7 5.3l0.5 4.5c-48.1 3.5-94.2 6.8-102.9 6.8h-0.4c-32.6-0.8-42.4-4.4-45.2-6.1 1.5-1.8 6.1-6.1 19.6-12.5 10.2-4.9 20-8.4 20.4-8.6l5.2-1.9-1.1-11.9h-6.5c-2.2 0-26.8 6.4-46.7 13.2-42.9 14.7-49.1 24.9-49.5 33-0.7 14.6 20.3 22.8 72.4 28z" />
      <path
        d="m441.4 714.7c-63.7 0-96.5-8.6-96.8-8.7l-3.2-0.9c9.8 0.3 20.3 0.5 31.5 0.5 64.6 0 152.9-5.7 262.4-17h0.2c61.2-8.3 88.1-28.8 92.7-45.1 2.1-7.2 0.1-13.9-5.4-18.4l-11.3-9.4-0.4 14.3c-2.2 3.9-20.6 13.7-75.9 24.3-38.1 7.3-84.2 13.1-112 14.1-10.1 0.4-20.8 0.5-31.8 0.5-37.8 0-76.3-2.1-113.6-4.2-8.8-0.5-17.7-1-26.5-1.4h-0.1c-31.2-1.1-34.3-7.1-34.3-7.1-0.1-0.8 1.5-5.1 11.4-12.3 8-5.8 17.8-10.8 23.9-13.2l24.6-9.5-26.1-4.2c-4.4-0.7-9.7-1.1-15.5-1.1-25.6 0-73.3 7.6-92.7 28.1-5.8 6.1-8.5 12.9-8.1 20.1v1c-0.1 3.5-0.3 10.7 6.5 17.8 10.9 11.2 37.3 18.2 80.3 21l16.8 14.2c1.5 1.3 38.7 31.8 125.6 31.8 27.1 0 56.5-3 87.4-9 52.9-10.2 151.5-38.7 208.5-63.4l-4.8-13.4c-143.9 41.9-249.8 50.6-313.3 50.6z"
        fill="#00f"
      />
      <path d="m545.5 582.5c-22.3 4.7-43.5 7.1-62.9 7.1-49.6 0-74.9-15.4-81.5-20.1l-4.6-3.3-32.5 28.2 5.9 5.4c1.4 1.3 36.1 32.3 117.7 32.3 38 0 80.4-6.8 125.8-20.2l25.5-7.5-91.8-22.2z" />
    </g>
  </SvgIcon>
);
