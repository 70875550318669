import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const QLIKIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="43.707mm"
    height="42.392mm"
    version="1.1"
    viewBox="0 0 43.707 42.392"
    {...props}
  >
    <defs>
      <clipPath id="clipPath66">
        <path d="m0 0h612v792h-612z" />
      </clipPath>
    </defs>
    <g transform="translate(-109.56 -9.8817)">
      <g
        transform="matrix(2.3551 0 0 -2.3551 -1215.2 89.956)"
        clipPath="url(#clipPath66)"
      >
        <g transform="translate(580.88 18.719)">
          <path
            d="m0 0-2.418 2.029c0.843 1.334 1.333 2.914 1.333 4.609 0 4.773-3.869 8.643-8.642 8.643s-8.643-3.87-8.643-8.642c0-4.773 3.87-8.643 8.643-8.643 1.852 0 3.566 0.584 4.973 1.575l2.579-2.164s0.383-0.321 0.705 0.062l1.532 1.826s0.322 0.383-0.062 0.705m-4.417 6.638c0-2.933-2.377-5.31-5.31-5.31s-5.311 2.377-5.311 5.311c0 2.932 2.378 5.31 5.311 5.31s5.31-2.378 5.31-5.311"
            fill="#009c4d"
          />
        </g>
        <g transform="translate(567.75 25.358)">
          <path
            d="m0 0c0 1.88 1.524 3.404 3.404 3.404s3.404-1.524 3.404-3.404-1.524-3.405-3.404-3.404c-1.88 0-3.404 1.524-3.404 3.404"
            fill="#54565b"
          />
        </g>
        <g transform="translate(579.42 31.45)">
          <path
            d="m0 0c0 0.521 0.425 0.941 0.941 0.941 0.521 0 0.941-0.425 0.941-0.941s-0.42-0.941-0.941-0.941c-0.516 0-0.941 0.425-0.941 0.941m0.941 0.815c-0.445 0-0.79-0.362-0.79-0.815 0-0.455 0.34-0.815 0.79-0.815s0.79 0.36 0.79 0.815c0 0.453-0.34 0.815-0.79 0.815m-0.216-1.386h-0.126v1.147h0.415c0.199 0 0.352-0.1 0.352-0.317 0-0.181-0.098-0.302-0.272-0.319l0.323-0.511h-0.156l-0.305 0.511h-0.231zm0 0.621h0.259c0.151 0 0.256 0.033 0.256 0.209 0 0.149-0.113 0.206-0.256 0.206h-0.259z"
            fill="#54565b"
          />
        </g>
      </g>
    </g>
  </SvgIcon>
);
