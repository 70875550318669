import { Grid, IconButton, Typography } from "@mui/material";
import React from "react";
import { getWindowDimensions } from "../../helper/Dimension";
import Footer from "../Footer/Footer";
import Robot from "../Robot/Robot";
import Separator from "../Seperator/Separator";
import CloseFullscreenRoundedIcon from "@mui/icons-material/CloseFullscreenRounded";
import FullscreenRoundedIcon from "@mui/icons-material/FullscreenRounded";
import { isMobile } from "react-device-detect";

import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

export function BottomView() {
  const [showResults, setShowResults] = React.useState(false);

  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }));

  function click() {
    setShowResults(!showResults);
  }

  return (
    <Grid
      position="relative"
      top="10vh"
      textAlign={
        isMobile
          ? getWindowDimensions().width >= 600
            ? "right"
            : "center"
          : "right"
      }
    >
      <Separator />
      <HtmlTooltip
        title={
          !showResults ? (
            <React.Fragment>
              <Typography color="primary">Show Chat</Typography>
              {"This will show the "} <b>{"Chat-Bot"}</b> .{" "}
              {"The Bot can introduce you to the employee. Amazing right?"}
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Typography color="primary">Hide Chat</Typography>
              {"You got enough from the "} <b>{"Chat-Bot"}</b> .{" "}
              {"Just hide it then :)"}
            </React.Fragment>
          )
        }
      >
        <IconButton color="primary" onClick={click}>
          {!showResults ? (
            <FullscreenRoundedIcon />
          ) : (
            <CloseFullscreenRoundedIcon />
          )}
          {!showResults ? "(Show" : "(Hide "} <p> &nbsp;Chat)</p>
        </IconButton>
      </HtmlTooltip>
      <Robot isVisible={showResults} />
      <Separator />
      <Footer />
    </Grid>
  );
}

export default BottomView;
