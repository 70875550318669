import { Grid, Stack } from "@mui/material";

import { Container } from "@mui/system";
import React from "react";
import Header from "../Header/Header";
import parse from "html-react-parser";
import Footer from "../Footer/Footer";

export interface ErrorViewProps {
  message: string;
}

export const ErrorView = ({ message }: ErrorViewProps) => {
  return (
    <div>
      <Header />
      <Container>
        <div className="error">
          <Stack spacing={2}>
            <Grid alignItems="center">{parse(message)}</Grid>
          </Stack>
        </div>
      </Container>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default ErrorView;
