// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  //aws_appsync_graphqlEndpoint:
  // "https://bhoduhamk5gs5nmvouju3hwtpm.appsync-api.eu-central-1.amazonaws.com/graphql",
  //aws_appsync_apiKey: "da2-jl7tkxsrd5d47ngvsapzwtgvui",
  aws_appsync_graphqlEndpoint:
    "https://qo5ksbqxaffcnajk6coalp7qma.appsync-api.eu-central-1.amazonaws.com/graphql",
  aws_appsync_region: "eu-central-1",
  aws_appsync_authenticationType: "API_KEY",
  aws_appsync_apiKey: "da2-3n4zum4d6rcdpfmdrx3iclmspm",
};
export default awsmobile;
