import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const VEEVAIcon = (props: SvgIconProps) => (
  <SvgIcon width="86.73" height="87.865" viewBox="0 0 86.73 87.865" {...props}>
    <g transform="scale(2.7019)" fill="#f89921">
      <path d="m0 0h5.56l10.58 21.26 10.5-21.26h5.46l-16.02 32.52z" />
    </g>
    <path
      d="m23.831 0.16211 19.562 38.988 19.508-38.988z"
      fill="#898b8d"
      strokeWidth="2.7019"
    />
  </SvgIcon>
);
