import { Grid, Paper, styled, Typography } from "@mui/material";
import React from "react";

/*
Dirty seperator for elements..
*/
export const Separator = () => {
  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(0),
    textAlign: "left",
    color: theme.palette.text.secondary,
  }));

  return (
    <Grid>
      <Grid item xs={6} md={6}>
        <Item>
          <Typography
            align="center"
            marginBottom="4vw"
            variant="h3"
          ></Typography>
        </Item>
      </Grid>
    </Grid>
  );
};

export default Separator;
