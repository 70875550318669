import * as Icons from "../components/icons";
import WorkspacePremiumOutlined from "@mui/icons-material/WorkspacePremiumOutlined";

/* 
This function uses the Certification name and looks for an specific name like k8s, aws etc to find an sufficient icon.
If there is no sufficient icon, it will return a default badge icon.
 */
export function getCertIconByName(aiCertName: string): JSX.Element {
  if (
    aiCertName.toLowerCase().includes("k8s") ||
    aiCertName.toLowerCase().includes("kubernetes")
  ) {
    return <Icons.K8SIcon />;
  }

  if (
    aiCertName.toLowerCase().includes("aws") ||
    aiCertName.toLowerCase().includes("amazon")
  ) {
    return <Icons.AWSIcon />;
  }

  if (aiCertName.toLowerCase().includes("azure")) {
    return <Icons.AZUREIcon />;
  }

  if (aiCertName.toLowerCase().includes("veeva")) {
    return <Icons.VEEVAIcon />;
  }

  if (aiCertName.toLowerCase().includes("google")) {
    return <Icons.GOOGLEIcon />;
  }

  if (aiCertName.toLowerCase().includes("vmware")) {
    return <Icons.VMWAREIcon />;
  }

  if (aiCertName.toLowerCase().includes("sharepoint")) {
    return <Icons.SHAREPOINTIcon />;
  }

  if (aiCertName.toLowerCase().includes("microsoft")) {
    return <Icons.MICROSOFTIcon />;
  }

  if (aiCertName.toLowerCase().includes("qlik")) {
    return <Icons.QLIKIcon />;
  }

  if (aiCertName.toLowerCase().includes("java")) {
    return <Icons.JAVAIcon />;
  }

  return <WorkspacePremiumOutlined />;
}

/* 
This function uses the Certification name and looks for an specific name like k8s, aws etc to find an sufficient icon.
If there is no sufficient icon, it will return a default badge icon.
 */
export function getIconByName(aiIconName: string): JSX.Element {
  return <b> {aiIconName} </b>; /* 
  // -> If wanted, we can return the icons..
  if (isMobile) {
    if (window.matchMedia("(orientation: portrait)").matches) {
      return <b> {aiIconName} </b>;
    }
  }

  if (
    aiIconName.toLowerCase().includes("k8s") ||
    aiIconName.toLowerCase().includes("kubernetes")
  ) {
    return (
      <b>
        <Icons.K8SIcon fontSize="small" /> {aiIconName}
      </b>
    );
  }

  if (
    aiIconName.toLowerCase().includes("aws") ||
    aiIconName.toLowerCase().includes("amazon")
  ) {
    return (
      <b>
        <Icons.AWSIcon fontSize="small" /> {aiIconName}
      </b>
    );
  }

  if (aiIconName.toLowerCase().includes("azure")) {
    return (
      <b>
        <Icons.AZUREIcon fontSize="small" />
        {aiIconName}
      </b>
    );
  }

  if (aiIconName.toLowerCase().includes("veeva")) {
    return (
      <b>
        <Icons.VEEVAIcon fontSize="small" /> {aiIconName}
      </b>
    );
  }

  if (aiIconName.toLowerCase().includes("google")) {
    return (
      <b>
        <Icons.GOOGLEIcon fontSize="small" />
        {aiIconName}
      </b>
    );
  }

  if (aiIconName.toLowerCase().includes("vmware")) {
    return (
      <b>
        <Icons.VMWAREIcon fontSize="small" /> {aiIconName}
      </b>
    );
  }

  if (aiIconName.toLowerCase().includes("sharepoint")) {
    return (
      <b>
        <Icons.SHAREPOINTIcon fontSize="small" /> {aiIconName}
      </b>
    );
  }

  if (aiIconName.toLowerCase().includes("microsoft")) {
    return (
      <b>
        <Icons.MICROSOFTIcon fontSize="small" />
        {aiIconName}
      </b>
    );
  }

  if (aiIconName.toLowerCase().includes("qlik")) {
    return (
      <b>
        <Icons.QLIKIcon fontSize="small" /> {aiIconName}
      </b>
    );
  }

  if (aiIconName.toLowerCase().includes("java")) {
    return (
      <b>
        <Icons.JAVAIcon fontSize="small" /> {aiIconName}
      </b>
    );
  }

  return <b> {aiIconName} </b>; */
}
