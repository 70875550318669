import React from "react";
import { Grid, Paper, Stack, styled, Typography } from "@mui/material";
import "./Certificates.css";

import * as IconHelper from "../../helper/IconHelper";

import * as GraphAPI from "./../../API";
import { ProfileContext } from "../../App";

export const Certificates = () => {
  const profileData = React.useContext(ProfileContext);
  const certificates = getCerts(profileData?.getUserProfile!);

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    height: "auto",
  }));

  return (
    <div
      className="certificates"
      style={{ position: "relative", marginBottom: "1em", top: "10vh" }}
    >
      <Stack spacing={2}>
        {certificates!.map((certification, index) => (
          <Grid container alignItems="center">
            <Grid item xs={1} id="filler"></Grid>
            <Grid item xs={10} alignItems="stretch">
              <Item>
                <Typography variant="h4" marginLeft="0.5vw">
                  {IconHelper.getCertIconByName(certification!)} {""}{" "}
                  <b> {certification}</b>
                </Typography>
              </Item>
            </Grid>
          </Grid>
        ))}
      </Stack>
    </div>
  );
};

/*
This function gets the certificates out of the skillset of the UserProfile and returns them as an string-array..
 */
function getCerts(userProfile: GraphAPI.UserProfile) {
  var certificates = userProfile!.skillsets?.find(
    (certificate) =>
      certificate?.category === "Certifications" ||
      certificate?.category === "Zertifikate"
  )!;

  if (certificates === undefined) {
    return [];
  }

  if (certificates.skills === undefined || certificates.skills === null) {
    return [];
  }

  return certificates.skills!;
}

export default Certificates;
