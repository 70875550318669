import styled from "styled-components";

export const Box = styled.div`
  padding: 80px 60px;
  background-image: linear-gradient(
    120deg,
    #e60000 0%,
    #de004a 100%
  ) !important;
  /* background: #ffffff; */
  position: absolute;
  /* bottom: 0; */
  width: 100%;

  @media (max-width: 1000px) {
    padding: 70px 30px;
  }
`;

export const Seperator = styled.div`
  background: #de004a;
  color: #de004a;
  /* bottom: 0; */
  width: 100%;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1000px;
  margin: 0 auto;
  /* background: red; */
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 60px;
  font-family: "source sans pro light";
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(185px, 1fr));
  grid-gap: 20px;

  @media (max-width: 1000px) {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
`;

export const FooterLink = styled.a`
  color: #ffffff;
  margin-bottom: 20px;
  font-size: 18px;
  text-decoration: none;

  &:hover {
    color: white;
    transition: 200ms ease-in;
    font-weight: bold;
  }
`;

export const Heading = styled.p`
  font-size: 24px;
  color: #ffffff;
  margin-bottom: 40px;
  font-weight: bold;
  font-family: "source sans pro semibold";
`;
