import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const VMWAREIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="240.94"
    height="233.12"
    viewBox="0 0 30.805 29.812"
    {...props}
  >
    <g transform="translate(-131.58 -58.965)">
      <path
        transform="translate(131.57 58.965)"
        d="m7.1562 0c-1.2312 0-2.2188 0.9675-2.2188 2.1562v7.375h-2.7188c-1.2312-3e-7 -2.2188 0.97125-2.2188 2.1562v15.969c0 1.1888 0.9875 2.1562 2.2188 2.1562h16.5c1.2312-1e-6 2.25-0.9675 2.25-2.1562v-2.625h7.625c1.23 1e-6 2.2188-0.97125 2.2188-2.1562v-15.969c0-1.1863-0.98875-2.125-2.2188-2.125h-2.7188v-2.625c0-1.1887-0.99-2.1562-2.2188-2.1562zm0 1.2188h16.5c0.53875 1e-7 0.96875 0.415 0.96875 0.9375v2.625h-12.531c-1.2288-1e-7 -2.25 0.93875-2.25 2.125v2.625h-3.6562v-7.375c-2e-7 -0.5225 0.42625-0.9375 0.96875-0.9375zm4.9375 4.75h12.531v12.125c0 0.5225-0.43 0.96875-0.96875 0.96875h-2.6875v-7.375c1e-6 -1.185-1.0188-2.1562-2.25-2.1562h-7.625v-2.625c0-0.52125 0.46125-0.9375 1-0.9375zm13.781 0h2.7188c0.54-2e-7 0.96875 0.41625 0.96875 0.9375v15.969c0 0.52125-0.42875 0.9375-0.96875 0.9375h-7.625v-3.5312h2.6875c1.2287-1e-6 2.2188-0.99875 2.2188-2.1875zm-23.656 4.7812h2.7188v7.3438c0 1.1888 0.9875 2.1875 2.2188 2.1875h2.6875v2.5938c2e-7 1.185 1.0212 2.1562 2.25 2.1562h7.625v2.625c1e-6 0.52125-0.4575 0.9375-1 0.9375h-16.5c-0.54625-1e-6 -0.96875-0.41625-0.96875-0.9375v-15.969c1e-7 -0.52 0.4225-0.9375 0.96875-0.9375zm3.9688 0h3.6562v8.3125h-2.6875c-0.5425 0-0.96875-0.44625-0.96875-0.96875zm4.9062 0h7.625c0.5425 0 1 0.4175 1 0.9375v7.375h-8.625zm0 9.5312h8.625v3.5312h-7.625c-0.53875 0-1-0.41625-1-0.9375z"
        fill="#7498bf"
      />
    </g>
  </SvgIcon>
);
