import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import { resources } from "./locale/resources";
import { defaultLocale, Domain, cookieName } from "./locale";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init(
    {
      resources,
      debug: false,
      ns: [Domain.General],
      defaultNS: Domain.General,
      fallbackLng: {
        "en-GB": ["en"],
        en: ["en"],
        de: ["de"],
        default: [defaultLocale],
      },
      lng: "en",
      react: {
        useSuspense: false, // not yet supported by SSR
      },
      interpolation: {
        escapeValue: false, // react prevents xss by default
      },
      saveMissing: true,
      detection: {
        order: ["cookie", "navigator"],
        caches: ["cookie"],
        lookupCookie: cookieName,
      },
    },
    (err) => {
      if (err) return console.error(err);
    }
  );

export const useDomain = (domain: Domain | Domain[]) =>
  i18n.getFixedT(null, domain);

export default i18n;

export { Domain } from "./locale";
