import React from "react";
import { Grid, Paper, styled, Typography } from "@mui/material";

import * as GraphAPI from "./../../API";
import { ProfileContext } from "../../App";
import { fontFamily } from "@mui/system";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
  height: "100%",
}));

export function OtherSkills() {
  const profileData = React.useContext(ProfileContext);
  const other_skills = getOtherSkills(profileData?.getUserProfile!);

  return <Grid>{other_skills}</Grid>;
}

function getOtherSkills(userProfile: GraphAPI.UserProfile) {
  var skills = userProfile!.skillsets?.find(
    (certificate) =>
      certificate?.category === "Produktkenntnisse" ||
      certificate?.category === "Product Knowledge"
  )!;

  var test = getSubSkillsAsElement(skills);

  return test;

  //return [];
  /* 
  console.log(userProfile!.skillsets);

  if (skills == undefined) {
    skills = userProfile!.skillsets?.find(
      (skillset) => skillset?.subcategories
    )!;
  }
  if (skills == undefined) {
    return [];
  }

  return skills.skills!; */
}

function getSubSkillsAsString(
  skillSet: GraphAPI.UserProfileSkillsetSubCategory
) {
  var output = "";

  skillSet?.skills?.forEach((skill) => {
    output += skill + ", ";
  });

  //remove last two chars (', ')
  output = output.substring(0, output.length - 2);

  return output;
}

function getSubSkillsAsElement(
  skillSet: GraphAPI.UserProfileSkillset
): JSX.Element {
  return (
    <div
      className="skills"
      style={{ position: "relative", marginBottom: "5vh", top: "10vh" }}
    >
      <Grid
        container
        height="100%"
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        {skillSet.subcategories?.map((entry, index) => (
          <Grid id="test" item xs={2} sm={4} md={4} key={index}>
            <Item id="item">
              <Grid>
                <Typography
                  variant="h3"
                  gridRow={0}
                  textAlign="center"
                  style={{ wordWrap: "break-word" }}
                >
                  <b> {entry!.category} </b>
                </Typography>
                <Typography
                  variant="h4"
                  gridRow={2}
                  style={{ wordWrap: "break-word", marginTop: "20px" }}
                >
                  {getSubSkillsAsString(entry!)}
                  {/* {entry?.skills?.map((skill, index) => (
                <p>{skill}</p>
              ))} */}
                </Typography>
              </Grid>
            </Item>
          </Grid>
        ))}
        {/* {Array.from(other_skills).map((_, index) => (
        <Grid item xs={2} sm={4} md={4} key={index}>
          <Item>{_}</Item>
        </Grid>
     ))} */}
      </Grid>
    </div>
  );
}

export default OtherSkills;
