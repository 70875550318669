import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const MICROSOFTIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="533.63"
    height="533.73"
    version="1.1"
    viewBox="0 0 220.65 220.58"
    {...props}
  >
    <path d="m104.87 104.81h-104.87v-104.87h104.87z" fill="#f1511b" />
    <path d="m220.65 104.81h-104.87v-104.87h104.87z" fill="#80cc28" />
    <path d="m104.86 220.64h-104.86v-104.87h104.86z" fill="#00adef" />
    <path d="m220.65 220.64h-104.87v-104.87h104.87z" fill="#fbbc09" />
  </SvgIcon>
);
