import {
  Grid,
  LinearProgress,
  Paper,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { ProfileContext } from "../../App";
import { Domain } from "../../common/i18n/";
import * as GraphAPI from "./../../API";
import * as Encoding from "./../../helper/Encoding";
import parse from "html-react-parser";

export function Experience() {
  const profileData = React.useContext(ProfileContext);
  const skills = getSkills(profileData?.getUserProfile!);
  const history = getHistory(profileData?.getUserProfile!);

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "left",
    color: theme.palette.text.secondary,
    height: "100%",
  }));
  const { t } = useTranslation(Domain.General);
  return (
    <div
      className="experience"
      style={{ position: "relative", marginBottom: "1em", top: "10vh" }}
    >
      <Grid
        container
        spacing={2}
        alignItems="stretch"
        direction="row"
        justifyContent="center"
      >
        <Grid borderRadius="10px" item xs={6} md={6}>
          <Item>
            <Typography align="center" marginBottom="2vw" variant="h3">
              {t("special_field")}
            </Typography>
            {skills.map((skill, index) => (
              <div key={index}>
                <Typography
                  marginTop="1vw"
                  align="right"
                  variant="h5"
                  style={{ wordWrap: "break-word" }}
                >
                  {skill}
                </Typography>
                <LinearProgress
                  variant="determinate"
                  value={100}
                  /*value={((index + 1) / skills.length) * 100} */
                  /*value={(index + 1) * 15 * Math.random()} */
                />
              </div>
            ))}
          </Item>
        </Grid>
        <Grid item xs={6} md={6}>
          <Item>
            <Typography align="center" marginBottom="2vw" variant="h3">
              {t("experience_only")}
            </Typography>
            <Stack spacing={2}>
              {history!.map((entry, index) => (
                <Item key={index}>
                  <Typography variant="h5" style={{ wordWrap: "break-word" }}>
                    <b>{entry?.year!}</b> -{" "}
                    {parse(Encoding.transformString(entry?.title!))}
                  </Typography>
                </Item>
              ))}
            </Stack>
          </Item>
        </Grid>
      </Grid>
    </div>
  );
}

function getSkills(
  userProfile: GraphAPI.UserProfile
): (string | null | undefined)[] {
  var skillset = userProfile!.skillsets?.find(
    (skillset) =>
      skillset?.category === "Technologies & Tools" ||
      skillset?.category === "Technologien & Werkzeuge"
  )!;

  if (skillset !== undefined) {
    if (skillset!.skills !== undefined) {
      if (skillset!.skills!.length > 0) return skillset!.skills!;
    }
  }

  skillset = userProfile!.skillsets?.find(
    (skillset) =>
      skillset?.category === "Methodik" ||
      skillset?.category === "Systems Engineering"
  )!;

  if (skillset !== undefined) {
    if (skillset!.skills !== undefined) {
      if (skillset!.skills!.length > 0) return skillset!.skills!;
    }
  }

  if (skillset === undefined) {
    return [];
  }

  return skillset!.skills!;
}

function getHistory(userProfile: GraphAPI.UserProfile) {
  var history = userProfile!.career?.entries!;
  return history;
}

export default Experience;
