import { Grid } from "@mui/material";
import React from "react";
import { isMobile } from "react-device-detect";

import Popup from "../Popup/Popup";
import { getWindowDimensions } from "../../helper/Dimension";

export interface RobotViewProps {
  isVisible: boolean;
}

export const Robot = ({ isVisible }: RobotViewProps) => {
  if (isVisible) {
    if (isMobile) {
      return (
        <div>
          <Grid
            container
            spacing={0}
            direction="row"
            justifyContent={
              getWindowDimensions().width >= 600 ? "right" : "center"
            }
            height="400px"
          >
            <Grid
              item
              textAlign="right"
              border="double"
              width="100%"
              height="100%"
              maxHeight="400px"
              maxWidth="350px"
            >
              <Popup />
            </Grid>
          </Grid>
          <Grid>&nbsp;</Grid>
        </div>
      );
    }

    return (
      <div>
        <Grid
          container
          spacing={2}
          alignItems="stretch"
          direction="row"
          justifyContent="right"
          height="400px"
        >
          <Grid item xs={2}></Grid>
          <Grid
            item
            xs={6}
            textAlign="right"
            borderTop="double"
            borderLeft="double"
            borderBottom="double"
            borderRight="double"
            width="100%"
            height="100%"
            maxHeight="400px"
            maxWidth="700px"
          >
            <Popup />
          </Grid>
          <Grid item xs={2}></Grid>
        </Grid>
      </div>
    );
  }
  return <></>;
};

export default Robot;
