import { UserProfileAddress } from './../../../prod/src/API';
import VCard from 'vcard-creator';
import * as GraphAPI from './../API';

export class UserProfile {

  private _profileId: string;
  private _name: string;
  private _mail: string;
  private _phone: string;
  private _mobilePhone: string;
  private _company: string;
  private _role: string;
  private _homepage: string;
  private _profile: GraphAPI.UserProfile | null;
  private _address: GraphAPI.UserProfileAddress | null;
  private _addons: GraphAPI.UserProfileAddons | null;
  private _skills: (string | null | undefined)[];
  private _isCardView: boolean;

  constructor(profile: GraphAPI.UserProfile, addonProfile: GraphAPI.UserProfileAddons, isCardView: boolean ) {
    let locl_address: GraphAPI.UserProfileAddress;
    locl_address = { __typename: "UserProfileAddress", street: "Wolfenbüttler Straße 33", company: "fme AG", plz_city: "38102 Braunschweig", homepage: "https://fme.de" };

    if (addonProfile === undefined || profile === undefined) {
      this._profileId = "Undefined";
      this._name = "Undefined";
      this._mail = "Undefined";
      this._phone = "Undefined";
      this._mobilePhone = "Undefined";
      this._address = locl_address;
      this._company = "Undefined";
      this._homepage = "Undefined";
      this._role = "Undefined";
      this._profile = null;
      this._skills = [""];
      this._addons = null;
      this._isCardView = false;
      return;
    }

    var realProfile = profile as GraphAPI.UserProfile;
    this._profile = realProfile;
    this._addons = addonProfile;
    this._profileId = realProfile.id;
    this._name = this._profile.common?.name || "Undefined";
    this._mail = this._profile.userId?.includes("@") ? this._profile.userId : "info@fme.de";
    this._phone = addonProfile.phoneNumber || ""; //"+49 531 238540"; //Empfang.
    this._mobilePhone = addonProfile.mobileNumber || "";
    this._address = addonProfile.address || locl_address;
    this._homepage = addonProfile.address?.homepage || "https://www.fme.de";
    this._company = addonProfile.address?.company || "fme group"; //Group TBD:  AdvancedProfil: this._company = addonProfile.company || "fme AG"; 
    this._role = this._profile.common?.title || "Employee";
    this._skills = addonProfile.topSkills!;
    this._isCardView = isCardView;
    //this._skills = this.getSkills(realProfile);
  }

  //getter/setter
  get Id(): string {
    return this._profileId;
  }

  get FullName(): string {
    return this._name;
  }

  get LastName(): string {
    var aoLastname = "";
    var array = this._name.split(" ");
    var length = array.length;
    aoLastname = array[length - 1];
    return aoLastname;
  }

  //Just use the fullname minus lastname
  get FirstName(): string {
    var fullname = this._name;

    var firstname = fullname.replace(" " + this.LastName, "");

    return firstname.trim();
  }

  get Mail(): string {
    return this._mail;
  }

  get Phone(): string {
    return this._phone;
  }

  get MobilePhone(): string {
    return this._mobilePhone;
  }

  get Company(): string {
    return this._company;
  }

  get Role(): string {
    return this._role;
  }

  get Street(): string {
    return this._address?.street!;
  }

  get PlzCity(): string {
    return this._address?.plz_city!;
  }

  get Homepage(): string {
    return this._homepage!;
  }

  SwitchToCardView(isDBCOnly: boolean) {
    this._isCardView = isDBCOnly; 
  }

  MailToString(): string {
    return "mailto:" + this._mail;
  }

  skillByIndex(aiIndex: number): string {
    if (this._skills === null || this._skills === undefined) {
      return "";
    }
    var length = this._skills.length;

    if (length < 3 && aiIndex >= 2) {
      return "";
    }
    if (aiIndex >= length) {
      console.log("Index of " + aiIndex + " too high. Use latest index!");
      aiIndex = length - 1;
    }
    return this._skills[aiIndex]!;
  }

  get ProfileUrl(): string {
    return window.location.protocol + "//" + window.location.host + "/" + this._profileId + (this._isCardView ? "/card" : "");
  }

  get BookingLink(): string {
    return this._addons?.m365BookingLink!;
  }

  //TBD: LinkedIn, Xing etc! ;-)
  get LinkedInLink(): string {
    let link = "https://www.linkedin.com/feed/";
    console.log("no linkedin profile there..");
    return link;
  }

  transformPhone(): string {
    return this.formatPhoneNumber(this._phone);
  }

  transformMobilePhone(): string {
    return this.formatPhoneNumber(this._mobilePhone);
  }

  PhoneToString(): string {
    var number = this.transformPhone().trim().replaceAll(" ", "");
    return "tel:" + number;
  }

  MobilePhoneToString(): string {
    var number = this.transformMobilePhone().trim().replaceAll(" ", "");
    return "tel:" + number;
  }

  hasPhone(): boolean {
    if(this._phone === ""){
      return false;
    }
    return true;
  }
  
  hasMobilePhone(): boolean {
    if(this._mobilePhone === ""){
      return false;
    }
    return true;
  }

  hasBookingLink(): boolean {
    if(! this._addons?.m365BookingLink!.startsWith("https://outlook.office.com/bookwithme")){
      return false;
    }
    return true;
  }

  //ToDo: implement beatufiy algorithm for telephone-numbers
  formatPhoneNumber(number: string): string {
    //Filter only numbers from the input
    return number;
  }

  createVCARD = (): VCard => {
    const vcard = new VCard();
    vcard.addAddress(this._company, undefined, "Wolfenbuetteler Strasse 33", "Braunschweig"); //TBD: Address from Additional data?
    vcard.addLogoURL("https://dbc.cloudyfme.de/fme.svg");
    vcard.addEmail(this._mail, "WORK");
    vcard.addURL(this.ProfileUrl);
    vcard.addNote("The Digital Business Card is powered by fme group (https://fme.de).");
    vcard.addRole(this._role);
    vcard.addPhoneNumber(this.transformPhone(), "WORK");
    vcard.addCompany(this._company);
    vcard.addName(this.LastName, this.FirstName);
    vcard.addJobtitle(this._role);
    vcard.addPhotoURL("https://dbc.cloudyfme.de/fme.svg")
    return vcard;
  };

  /* private  getSkills(userProfile: GraphAPI.UserProfile): (string | null | undefined)[] {
    var skillset = userProfile!.skillsets?.find(
      (skillset) =>
        skillset?.category == "Technologies & Tools" ||
        skillset?.category == "Technologien & Werkzeuge"
    )!;
  
    console.log(skillset);
    
    return skillset!.skills!;
  
    return [
      ...skillset!.skills!.flatMap((skill) => skill?.split(",")),
      ...skillset!.subcategories!.flatMap((cat) => cat?.skills),
    ];
  }
   */
}