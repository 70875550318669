/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const generatePresignedImageUrl = /* GraphQL */ `
  query GeneratePresignedImageUrl($profileId: String) {
    generatePresignedImageUrl(profileId: $profileId) {
      body {
        message
        url
      }
      statusCode
    }
  }
`;
export const generatePresignedAudioUrl = /* GraphQL */ `
  query GeneratePresignedAudioUrl($profileId: String, $lang: String) {
    generatePresignedAudioUrl(profileId: $profileId, lang: $lang) {
      body {
        message
        url
      }
      statusCode
    }
  }
`;
export const getUserProfileAddons = /* GraphQL */ `
  query GetUserProfileAddons($id: ID!) {
    getUserProfileAddons(id: $id) {
      id
      profileId
      topSkills
      phoneNumber
      m365BookingLink
      mobileNumber
      address {
        company
        plz_city
        street
        homepage
      }
      createdAt
      updatedAt
    }
  }
`;
export const listUserProfileAddons = /* GraphQL */ `
  query ListUserProfileAddons(
    $filter: ModelUserProfileAddonsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserProfileAddons(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        profileId
        topSkills
        phoneNumber
        m365BookingLink
        mobileNumber
        address {
          company
          plz_city
          street
          homepage
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserProfile = /* GraphQL */ `
  query GetUserProfile($id: ID!) {
    getUserProfile(id: $id) {
      id
      profileId
      userId
      name
      mail
      created
      type
      version
      lastChanged
      language
      status
      common {
        name
        title
        birthYear
      }
      career {
        title
        entries {
          year
          title
        }
      }
      skillsets {
        category
        skills
        subcategories {
          category
          skills
        }
      }
      activities {
        title
        entries {
          description
          from
          to
          projectRef
          archived
          roles
          tasks
          technologies
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const listUserProfiles = /* GraphQL */ `
  query ListUserProfiles(
    $filter: ModelUserProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserProfiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        profileId
        userId
        name
        mail
        created
        type
        version
        lastChanged
        language
        status
        common {
          name
          title
          birthYear
        }
        career {
          title
        }
        skillsets {
          category
          skills
        }
        activities {
          title
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
